import type { AppProps } from "next/app";
import { ThemeProvider } from "styled-components";

import { AuthProvider, GlobalStyle, theme } from "@seriesfi/ui";

import { Provider } from "react-redux";
import { store } from "@/api/store/store";
import { QuillProviderWrapper } from "../components/QuillProviderWrapper";
import { Wrapper, Header } from "@/components/common/layout/components";

export default function App({ Component, pageProps }: AppProps) {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <GlobalStyle />
        <AuthProvider>
          <QuillProviderWrapper>
            <GlobalStyle />
            <Wrapper>
              <Header />
              <Component {...pageProps} />
            </Wrapper>
          </QuillProviderWrapper>
        </AuthProvider>
      </Provider>
    </ThemeProvider>
  );
}
