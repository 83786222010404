import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface Business {
  legalName: string;
  id: string;
}

// Define a type for the slice state
interface BusinessState {
  selectedBusiness: Business;
}

// Define the initial state using that type
const initialState: BusinessState = {
  selectedBusiness: {
    legalName: "",
    id: "",
  },
};

export const businessSlice = createSlice({
  name: "selectedBusiness",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setSelectedBusiness: (state, action: PayloadAction<Business>) => {
      state.selectedBusiness = action.payload;
    },
  },
});

export const { setSelectedBusiness } = businessSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectedBusinessSelector = (state: RootState) =>
  state.selectedBusiness.selectedBusiness;

export default businessSlice.reducer;
