import { Logo, Navigation } from "./components";
import { Container, Wrapper } from "./styles";

export const Header = () => {
  return (
    <Container>
      <Wrapper>
        <Logo />
      </Wrapper>
      <Navigation />
    </Container>
  );
};
