import { useGetSessionDataQuery } from "@/api/sessionApi";
import {
  Business,
  selectedBusinessSelector,
  setSelectedBusiness,
} from "@/api/store/slices/businessSlice";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useDefaultBusiness = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { isLoading, data: sessionData } = useGetSessionDataQuery("");
  const selectedBusiness = useSelector(selectedBusinessSelector);

  useEffect(() => {
    if (!selectedBusiness.id && sessionData) {
      const businesses = sessionData?.data?.businesses;
      const businessIdQueryParam = router?.query?.businessId;
      const defaultBusiness = businessIdQueryParam
        ? businesses.find(
            (business: Business) => business.id === businessIdQueryParam
          )
        : sessionData?.data?.businesses[0];

      dispatch(setSelectedBusiness(defaultBusiness));
    }
  }, [sessionData]);

  return { isLoading, sessionData };
};
