import styled from "styled-components";

export const Arrow = styled.svg.attrs({
  viewBox: "0 0 12 8",
})<{ $direction: "up" | "down" }>`
  width: 12px;
  height: 8px;
  margin-right: 2em;
  fill: ${({ theme }) => theme.colors.black};
  transform: rotate(
    ${({ $direction }) => ($direction === "up" ? "180" : "0")}deg
  );
  transition: all 0.25s ease-in;
  & > path {
    stroke: ${({ theme }) => theme.colors.black};
  }
  ${({ theme }) => theme.mediaQueries.tablet} {
    margin-right: 0;
  }
`;

export const Button = styled.button<{ $selected?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 44px;
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-family: inherit;
  font-size: ${({ theme }) => theme.font.fontSize.sm};
  font-weight: ${({ $selected, theme }) =>
    $selected ? theme.font.fontWeight.bold : theme.font.fontWeight.regular};
  color: ${({ theme }) => theme.colors.black};
  border-width: 0;
  background-color: transparent;
  transition: none;
  &:hover {
    color: ${({ $selected, theme }) =>
      $selected ? theme.colors.black : theme.colors.seriesRed};
  }
  ${({ theme }) => theme.mediaQueries.tablet} {
    font-size: ${({ theme }) => theme.font.fontSize.md};
  }
`;

export const Item = styled.li`
  display: flex;
  height: 44px;
  &:not(:first-child) {
    padding-top: 5px;
  }
  &:not(:last-child) {
    padding-bottom: 5px;
  }
`;

export const Items = styled.ul<{ $open: boolean; $businessCount: number }>`
  flex-direction: column;
  margin-top: 10px;
  list-style: none;
  border-top: solid 1px ${({ theme }) => theme.colors.gray[400]};
  margin-top: 10px;
  padding-top: 10px;
  overflow: auto;
  height: ${({ $businessCount }) => `${$businessCount * 55}px`};
  max-height: ${({ $open }) => ($open ? "500px" : "55px")};
  transition: all 0.25s ease-in;
  ${({ theme }) => theme.mediaQueries.tablet} {
    max-height: ${({ $open }) => ($open ? "500px" : "60px")};
    margin-top: 15px;
    padding-top: 15px;
  }
`;
