import * as React from 'react';

import { Outer, Inner } from './styles';

export const Wrapper = (props: React.PropsWithChildren) => {
  return (
    <Outer>
      <Inner>{props.children}</Inner>
    </Outer>
  );
};
