import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface BusinessUser {
  user: User;
  role: Role;
  createdAt: string;
  businessId: string;
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  createdAt: string;
}

export interface Role {
  name: string;
}

// Define a type for the slice state
interface UserState {
  selectedUser: BusinessUser;
}

// Define the initial state using that type
const initialState: UserState = {
  selectedUser: {
    user: {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      createdAt: "",
    },
    role: {
      name: "",
    },
    createdAt: "",
    businessId: "",
  },
};

export const userSlice = createSlice({
  name: "selectedUser",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setSelectedUser: (
      state: { selectedUser: any },
      action: PayloadAction<BusinessUser>
    ) => {
      state.selectedUser = action.payload;
    },
  },
});

export const { setSelectedUser } = userSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectedUserSelector = (state: RootState) =>
  state.selectedUser.selectedUser;

export default userSlice.reducer;
