import { selectedBusinessSelector } from "@/api/store/slices/businessSlice";
import { getCSRFToken } from "@/utils/cookie";
import { QuillProvider } from "@quillsql/react";
import { LoadingSpinner } from "@seriesfi/ui";
import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { useDefaultBusiness } from "./apps/hooks/useDefaultBusiness";

const NEXT_PUBLIC_INTEL_API_BASE_URL = process.env
  .NEXT_PUBLIC_INTEL_API_BASE_URL as string;
const NEXT_PUBLIC_QUILL_PUBLIC_KEY = process.env
  .NEXT_PUBLIC_QUILL_PUBLIC_KEY as string;

export const QuillProviderWrapper: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { isLoading } = useDefaultBusiness();

  const selectedBusiness = useSelector(selectedBusinessSelector);

  if (isLoading || !selectedBusiness.id) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <QuillProvider
      queryEndpoint={`${NEXT_PUBLIC_INTEL_API_BASE_URL}?businessId=${selectedBusiness.id}`}
      queryHeaders={{
        "x-series-csrf-token": getCSRFToken(),
      }}
      publicKey={NEXT_PUBLIC_QUILL_PUBLIC_KEY}
      withCredentials={true}
      organizationId={selectedBusiness.id}
    >
      {children}
    </QuillProvider>
  );
};
