import { useState } from "react";

import { Arrow, Button, Items, Item } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import {
  Business,
  selectedBusinessSelector,
  setSelectedBusiness,
} from "@/api/store/slices/businessSlice";
import { useRouter } from "next/router";
import { useDefaultBusiness } from "@/components/apps/hooks/useDefaultBusiness";

export const Companies = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const router = useRouter();

  const { isLoading, sessionData } = useDefaultBusiness();

  const selectedBusiness = useSelector(selectedBusinessSelector);
  const { businesses } = sessionData?.data || {};

  if (isLoading || !selectedBusiness.id) {
    return null;
  }

  const toggleMenu = () => {
    setOpen(!open);
  };

  const onClickBusiness = (newSelectedBusiness: Business) => {
    dispatch(setSelectedBusiness(newSelectedBusiness));
    const newPath = `/?businessId=${newSelectedBusiness.id}`;
    router.push(newPath);
    toggleMenu();
  };

  const businessCount = businesses?.length;

  return (
    <Items $open={open} $businessCount={businessCount}>
      <Item>
        <Button $selected onClick={toggleMenu}>
          {selectedBusiness.legalName}
          <Arrow $direction={open ? "up" : "down"}>
            <path
              d="M1.5 1.75L6 6.25L10.5 1.75"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </Arrow>
        </Button>
      </Item>
      {open &&
        businesses
          .filter((business: Business) => business.id !== selectedBusiness.id)
          .map((business: Business) => (
            <Item key={business.id}>
              <Button onClick={() => onClickBusiness(business)}>
                {business.legalName}
              </Button>
            </Item>
          ))}
    </Items>
  );
};
