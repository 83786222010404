import { configureStore } from "@reduxjs/toolkit";
import { productApi } from "../productApi";
import { businessApi } from "../businessApi";
import { sessionApi } from "../sessionApi";
import selectedBusinessReducer from "./slices/businessSlice";
import selectedUserReducer from "./slices/userSlice";

export const store = configureStore({
  reducer: {
    [productApi.reducerPath]: productApi.reducer,
    [businessApi.reducerPath]: businessApi.reducer,
    [sessionApi.reducerPath]: sessionApi.reducer,
    selectedBusiness: selectedBusinessReducer,
    selectedUser: selectedUserReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      productApi.middleware,
      businessApi.middleware,
      sessionApi.middleware
    ),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
