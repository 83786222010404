import NextLink from "next/link";
import styled, { css } from "styled-components";

export const Item = styled.li<{ $push?: boolean }>`
  display: flex;
  height: 44px;
  margin-top: ${({ $push }) => ($push ? "auto" : "0")};
  &:not(:first-child) {
    padding-top: 5px;
  }
  &:not(:last-child) {
    padding-bottom: 5px;
  }
  ${({ theme }) => theme.mediaQueries.tablet} {
    margin-top: 0;
  }
`;

export const Items = styled.ul`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  list-style: none;
  ${({ theme }) => theme.mediaQueries.tablet} {
    flex-grow: 0;
  }
`;

export const Link = styled(NextLink)<{ $active?: boolean; $badge?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  text-decoration: none;
  font-size: ${({ theme }) => theme.font.fontSize.sm};
  font-weight: ${({ $active, theme }) =>
    $active ? theme.font.fontWeight.bold : theme.font.fontWeight.regular};
  color: ${({ theme }) => theme.colors.black};
  border-style: solid;
  border-color: ${({ $active, theme }) =>
    $active ? theme.colors.black : "transparent"};
  border-right-width: ${({ $active }) => ($active ? "4px" : "0")};
  cursor: ${({ $active }) => ($active ? "default" : "pointer")};
  &:hover {
    color: ${({ $active, theme }) =>
      $active ? theme.colors.black : theme.colors.seriesRed};
    border-right-width: 4px;
    border-color: ${({ $active, theme }) =>
      $active ? theme.colors.black : theme.colors.gray[400]};
  }
  ${({ $badge, theme }) =>
    $badge
      ? css`
          &:after {
            content: "${$badge}";
            display: block;
            height: 1.5em;
            padding: 0 0.5em;
            margin-left: 0.5em;
            font-size: ${theme.font.fontSize.xs};
            color: ${theme.colors.white};
            text-align: center;
            line-height: 1.5em;
            background-color: ${theme.colors.seriesRed};
            border-radius: 0.75em;
          }
        `
      : css``}
  ${({ theme }) => theme.mediaQueries.tablet} {
    font-size: ${({ theme }) => theme.font.fontSize.md};
    border-right-width: 0;
    &:hover {
      border-right-width: 0;
    }
  }
`;

export const Wrapper = styled.div<{ $open: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 0 10px 30px;
  ${({ theme }) => theme.mediaQueries.tablet} {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    padding: 100px 20px 20px;
    transform: translate(${({ $open }) => ($open ? "0" : "100%")});
    background-color: ${({ theme }) => theme.colors.white};
    background-image: url(/images/menu-bg.png);
    background-position: left bottom;
    background-size: 66%;
    background-repeat: no-repeat;
  }
  ${({ theme }) => theme.mediaQueries.mobile} {
    background-size: contain;
  }
`;
