import styled from "styled-components";

export const PageContainer = styled.main`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 32px;
`;

export const PageHeader = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  > div {
    display: flex;
    margin-top: 10px;
    > div {
      > h1 {
        font-size: 40px;
        font-weight: ${(props) => props.theme.font.fontWeight.semibold};
      }
      > h3 {
        font-size: 18px;
        font-weight: ${(props) => props.theme.font.fontWeight.medium};
        &:not(:first-child) {
          margin-top: 5px;
        }
      }
    }
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 80px;
  & > button {
    margin: 0 10px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  ${(props) => props.theme.mediaQueries.tablet} {
    position: fixed;
    left: 0;
    bottom: 0;
    justify-content: center;
    width: 100vw;
    height: auto;
    padding: 20px;
    background-color: ${(props) => props.theme.colors.white};
    border-top: solid 1px ${(props) => props.theme.colors.neutral[500]};
    & > button {
      flex-grow: 1;
    }
  }
`;

export const PageContent = styled.div`
  flex-grow: 1;
  margin-top: 48px;
  ${(props) => props.theme.mediaQueries.tablet} {
    margin-top: 30px;
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledInlineNotification = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 10px 15px;
  gap: 18px;

  width: 100%;
  height: 54px;
  border-radius: 8px;

  background: var(--notification-info);
  color: white;
`;
