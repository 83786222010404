import styled, { css } from "styled-components";

export const Button = styled.button<{ $open?: boolean }>`
  display: none;
  ${({ theme }) => theme.mediaQueries.tablet} {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin: 0;
    padding: 0;
    border-width: 0;
    background: none;
    & > span {
      font-size: 0;
      position: relative;
      transition: background 10ms 300ms ease;
      &,
      &:before,
      &:after {
        display: block;
        width: 16px;
        height: 2px;
        border-radius: 1px;
        background-color: ${({ theme }) => theme.colors.black};
      }
      &:before,
      &:after {
        left: 0;
        content: "";
        position: absolute;
        transition: top 300ms 350ms ease, transform 300ms 50ms ease;
      }
      &:before {
        top: -6px;
      }
      &:after {
        top: 6px;
      }
    }
  }
  ${({ $open }) =>
    $open
      ? css`
          & > span {
            background: transparent;
            &:after,
            &:before {
              top: 0;
              transition: top 300ms 50ms ease, transform 300ms 350ms ease;
            }
            &:before {
              transform: rotate(45deg);
            }
            &:after {
              transform: rotate(-45deg);
            }
          }
        `
      : css``}
`;

export const Container = styled.header`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 280px;
  height: 100vh;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.gray[200]};
  ${({ theme }) => theme.mediaQueries.tablet} {
    z-index: 1;
    display: block;
    overflow: visible;
    width: 100%;
    height: 80px;
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

export const Wrapper = styled.div`
  margin: 32px auto 50px;
  ${({ theme }) => theme.mediaQueries.tablet} {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 20px;
    background-color: ${({ theme }) => theme.colors.white};
  }
`;
